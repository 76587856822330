.bubbleFrame {
    width: 100%;
    max-width: 500px;
    height: 350px;
  }

  .childComponent {
    width: 100%; /* width expands to fit bubble */
    height: 100%; /* width expands to fit bubble */
    border-radius: 50%; /* rounded border forms a circle */
    background-color: red; /* For browsers that do not support gradients */
    background-image: linear-gradient(180deg, #4d4d4d, #3a3a3a);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    transition: 0.1s;
    font-size: 13px;
  }


  .childComponentSelected {
    width: 100%; /* width expands to fit bubble */
    height: 100%; /* width expands to fit bubble */
    border-radius: 50%; /* rounded border forms a circle */
    background-color: red;
    background-image: linear-gradient(180deg, #F45D5E, #BF1327);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    scale: 1.1;
    transition: 0.1s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    font-size: 13px;
  }

  .childComponentSuperSelected {
    width: 100%; /* width expands to fit bubble */
    height: 100%; /* width expands to fit bubble */
    border-radius: 50%; /* rounded border forms a circle */
    background-color: rgb(12, 52, 78);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  